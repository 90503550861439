@import '../constants';

.sideMenuHeader {
    padding: 6px 16px;
    color: white;
    background-color: $secondaryBackground;
    font-weight: 700;
    font-variant: small-caps;
    font-feature-settings: normal;
    font-size: 16px;
    letter-spacing: 2px;
}