.wrapper {
    position:relative;
    width: 30px;
    height: 30px;
}

.img, .cross-img {
    position: absolute;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;
}

.cross-img {
    opacity: 0.8;
}