.tableFixHeadFoot          {
    overflow: auto;
    height: 88vh;
}
.tableFixHeadFoot thead,
.tableFixHeadFoot tfoot {
    position: sticky;
}

.tableFixHeadFoot thead {
  inset-block-start: 0; /* "top" */
}
.tableFixHeadFoot tfoot {
  inset-block-end: 0; /* "bottom" */
}

.tableCellAlignCenter {
  vertical-align: middle;
  text-align: center;
}

.footerAlignCenter {
  display: grid;
  place-items: center;
}