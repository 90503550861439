.topImageContainer {
    position: relative;    
    overflow: hidden;    

    img {
        position: absolute;
        margin-top: -5%;
        left: 0;
        top: 0;
        width:100%;
    }
}

.centerImageContainer {
    img {
        width: 100%;
        margin-bottom: 20px;
    }
}

.overlayHeader {
    position: absolute;
    top: 30%;
    left: 5%;
    
    p {
        color:white;
        font-size: 3.7vw;
        font-weight: 500;
    }
}

.infographic {
    margin-top: 100px;
    width: 100%;
}

.footerList {
    ul {
        margin: 1em
    }

    li {
        list-style-type: none;
    }

    a {
        color: white;

        &:hover {
            color: red;
        }
    }
}