@import './constants';

.layout {
    .layoutContent {
        margin-left: $sideMenuWidth;
        margin-top: $topMenuHeight;
        padding: 32px 32px;
        background-color: white;  
    }
}

@media screen and (max-width: 576px) {
    .layout {
        .layoutContent {
            margin-left: 0;
            padding: 32px 0px;
        }
    }
}