@import '../constants';

.sideMenu {
    position: fixed;
    height: 100%;
    width: $sideMenuWidth;
    top: $topMenuHeight;
    margin: 0;
    padding: 25px 0px;    
    background-color: #f1f1f1;    
    overflow: auto;
}

@media screen and (max-width: 576px) {
    .sideMenu{
        display: none;
    }
}