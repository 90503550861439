.tableFixHeadFoot          {
    overflow: auto;
    height: 75vh;
}
.tableFixHeadFoot thead,
.tableFixHeadFoot tfoot {
    position: sticky;
}

.tableBorderedRed{
  border:3px solid lightsalmon!important;
}

.tableBorderedGreen{
  border:3px solid lime!important;
}

.tableFixHeadFoot thead {
  inset-block-start: 0; /* "top" */
}
.tableFixHeadFoot tfoot {
  inset-block-end: 0; /* "bottom" */
}

.tableCellAlignLeft {
  vertical-align: middle;
  text-align: left
}

.tableCellAlignRight {
  vertical-align: middle;
  text-align: right
}

.tableCellAlignCenter {
  vertical-align: middle;
  text-align: center
}

.tableCellGray {
  background-color: lightgrey;
}
.tableCellGreen {
  background-color: rgba(0, 255, 0, 0.4);
}
.tableCellLightGreen {
  background-color: rgba(0, 255, 0, 0.2);
}
.tableCellOrange {
  background-color: rgba(255, 166, 0, 0.4);
}

.tableCellTextSmall {
  font-size: 13px;
}

.textColorBlue {
  color: darkblue;
}

.textColorPurple {
  color: purple;
}

.textColorRed {
  color: darkred;
}

.itemsInline {
  margin: 5px;
  display: inline-block;  
}