.tableSize {
    table-layout: fixed;
    width: 150px;
}

.flexContainer {
    display: flex;    
    align-items: center;

    .flexItemRight {
        margin-left: auto;
    }
}