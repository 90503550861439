@import '../constants';

.navMenu {    
    .topbar {
        position: fixed;
        width: 100%;
        height: $topMenuHeight;
        top: 0;
        left:0;
        z-index: 100;
        background-color: rgb(248, 248, 248);
    }   
      
    .box-shadow {
        box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
    }

    .navLogo {
        max-width: 300px;
        max-height: 60px;
        margin-top: -20px;

        img {
            width: 100%;  
        } 
    }
}

@media screen and (max-width: 575px) {
    .navbarUser {
        max-width: 0;
    }
}

@media screen and (max-width: 392px) {
    .navMenu {
        .navLogo {
            max-width: 200px;
            max-height: 20px;
        }
    }
}