html {
    font-size: 12px;
  }
  
  @media (min-width: 768px) {
    html {
      font-size: 14px;
    }
  }

:global(.mark) {
    background-color: red;
}