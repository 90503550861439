.logoImage {
    display: inline-block;
    height: 30px;
}

.logoButton {
    display: inline-block;
    margin-right: 20px;
}

.reportButton {
    float: right;
}

.flexContainer {
    display: flex;    
    align-items: center;
  
    .flexItemRight {
        margin-left: auto;
    }
}