.tableCellGreen {
    background-color: rgba(0, 255, 0, 0.4);
}
  
.tableCellRed {
  background-color: rgba(255, 166, 0, 0.4);
}

.flexContainer {
  display: flex;    
  align-items: center;

  .flexItemRight {
      margin-left: auto;
  }
}