@media screen and (max-width: 575px) {
    .userInfo {
        display: none
    }

    .loginDropdown{
        position: absolute !important;
        transform: translate3d(-2px, 38px, 0px);
        display: block
    }
}