.notifier {
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 1;

    .notification {
        min-width: 400px;
        background-color: rgb(233, 233, 233);
    }
}