.flexContainer {
    display: flex;    
    align-items: center;

    .flexItemRight {
        margin-left: auto;
    }

    .empty {
        font-style: italic;
        color: gray;
    }
}